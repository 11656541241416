<template>
  <div class="pageWrap">
    <div class="tab">
      <a-tabs v-model:activeKey="activeKey" @change="tabChange">
        <a-tab-pane key="code" tab="Code 管理"></a-tab-pane>
        <a-tab-pane key="TBDCode" tab="TBD Code管理"></a-tab-pane>
      </a-tabs>
    </div>
    <div class="tab-container">
      <FilterLayout
        @doReset="resetForm"
        @doSearch="doSearch"
        @doCollapseEffect="getChartContainerStyle"
        ref="filterLayoutRef"
        @showMoreForm="showMoreForm"
        :moreBtnVisible="moreBtnVisible"
        :isShowMoreFlag="isShowMoreList">
        <template #customform>
          <a-form
              ref="formRef"
              class="form rms-form box"
              layout="vertical"
              :model="formData"
          >
            <template v-for="(item,index) in queryList" :key="index">
              <template v-if="index<=formLength">
                <a-form-item :label="item.code" name="name" class="rms-form-item">
                  <template v-if="item.type==='3'">
                    <a-input
                        v-model:value="formData[item.inputDataType]"
                        :placeholder="getPlaceholder(item.inputDataType)"
                        allowClear
                        autocomplete="off"
                    />
                  </template>
                  <template v-if="item.type==='5'">
                    <SelectWithAll
                        v-model:value="formData[item.inputDataType]"
                        placeholder="请选择"
                        mode="multiple"
                        showArrow
                        :maxTagCount="1"
                        allowClear
                        :options="getOptions(item.inputDataType)"
                        @change="handleAccountPlChange(item.inputDataType)"
                        :tag-count="(item.inputDataType==='projectStatus'||item.inputDataType==='codeType')?3:1"
                    >
                    </SelectWithAll>
                  </template>
                </a-form-item>
              </template>
            </template>
            <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
              <template v-for="(item,index) in queryList" :key="index">
                <template v-if="index>formLength">
                  <a-form-item :label="item.code" name="name" class="rms-form-item">
                    <template v-if="item.type==='3'">
                      <a-input
                          v-model:value="formData[item.inputDataType]"
                          :placeholder="getPlaceholder(item.inputDataType)"
                          allowClear
                          autocomplete="off"
                      />
                    </template>
                    <template v-if="item.type==='5'">
                      <SelectWithAll
                          v-model:value="formData[item.inputDataType]"
                          placeholder="请选择"
                          mode="multiple"
                          showArrow
                          :maxTagCount="1"
                          allowClear
                          :options="getOptions(item.inputDataType)"
                          @change="handleAccountPlChange(item.inputDataType)"
                          :tag-count="(item.inputDataType==='projectStatus'||item.inputDataType==='codeType')?3:1"
                      >
                      </SelectWithAll>
                    </template>
                  </a-form-item>
                </template>
              </template>
            </MoreFormList>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
            <Tooltip title="导出">
              <a-button
                  class="ml12"
                  type="primary"
                  ghost
                  @click="handleExport"
                  v-auth="['system:codeMaintain:export']"
              >
                <ExportOutlined />
              </a-button>
            </Tooltip>
            <Tooltip title="下载模板">
              <a-button
                  class="ml12"
                  type="primary"
                  ghost
                  @click="handleDownloadTemplate"
                  v-auth="['system:codeMaintain:downloadTemplate']"
              >
                <DownloadOutlined />
              </a-button>
            </Tooltip>
            <Tooltip title="导入">
              <a-button
                  class="ml12"
                  type="primary"
                  ghost
                  @click="handleImport"
                  v-auth="['system:codeMaintain:import']"
              >
                <ImportOutlined />
              </a-button>
            </Tooltip>
          </div>
        </template>
      </FilterLayout>
      <div class="table-wrap" style="position: relative">
        <a-table
          ref="tableRef"
          :columns="columns"
          :data-source="tableData"
          rowKey="id"
          :scroll="{
            y: tableHeight,
            x: 1300,
            scrollToFirstRowOnChange: true,
          }"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #status="{ record }">
            <a-tag :class="`status ${record.projectStatus==='To be open'?'toBeOpen':record.projectStatus}`">
              {{record.projectStatus}}
            </a-tag>
          </template>
        </a-table>
        <div
          class="arve-tip"
          v-auth="['system:codeMaintain:import']"
          v-if="dataBoxShow"
        >
          <span class="tabText"
            >{{ data.tabName }}<span style="color: #b3b3b3">: </span></span
          >
          <span class="text">新增数据数:</span>
          {{ data.insertCount ? data.insertCount : 0 }}
          <span class="text">, 更新数据数:</span>
          {{ data.updateCount ? data.updateCount : 0 }}
          <span class="text">,任务状态:</span>
          {{ data.status ? data.status : "无" }}
          <span class="text">, 操作人:</span>
          {{ data.operator ? data.operator : "无" }}
          <span class="text">, 操作时间:</span>
          {{ data.operationTime ? data.operationTime : "无" }}
        </div>
        <div
          class="arve-tip1"
          v-auth="['system:codeMaintain:import']"
          v-if="noDataShow"
        >
          <span class="tabText"
            >{{ data.tabName }}<span style="color: #b3b3b3">: </span></span
          >
          <span class="text">新增数据数:</span>
          {{ data.insertCount ? data.insertCount : 0 }}
          <span class="text">, 更新数据数:</span>
          {{ data.updateCount ? data.updateCount : 0 }}
          <span class="text">,任务状态:</span>
          {{ data.status ? data.status : "无" }}
          <span class="text">, 操作人:</span>
          {{ data.operator ? data.operator : "无" }}
          <span class="text">, 操作时间:</span>
          {{ data.operationTime ? data.operationTime : "无" }}
        </div>
      </div>
    </div>
    <import-modal
      v-model:visible="importModalVisible"
      :apiFuncName="activeKey === 'code' ? 'getCodeImport' : 'getTBDCodeImport'"
      @closeModal="handleCloseModal('import')"
      @resetTable="doUpdateImport"
      @insertModal="doInsertImport"
    />
    <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="sortFlag" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
  </div>
</template>
<script>
import {
  computed,
  defineComponent,
  nextTick,
  onActivated,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import { downloadFile, useTableHeight } from "@/utils/common";
import { cloneDeep } from "lodash";
import ImportModal from "@/components/ImportModal";
import { getStatusInAccount } from "@/utils/formatStatus";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import {
  DownloadOutlined,
  ExportOutlined,
  ImportOutlined
} from "@ant-design/icons-vue";

import Tooltip from '@/components/Tooltip'
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'
export default defineComponent({
  name: "CodeManagement",
  components: {
    ImportModal,
    DownloadOutlined,
    MoreFormList,
    FormModalBox,
    Tooltip,
    ExportOutlined,
    ImportOutlined
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();
    const route = useRoute();
    const dataBoxShow = ref(true);
    const noDataShow = ref(false);
    // 新增数据数:${StatusData.value.insertCount},
    //   更新数据数:${StatusData.value.updateCount},
    //   任务状态:${getStatusInAccount(StatusData.value.status)},
    //   操作人:${StatusData.value.operator},
    //   操作时间:${StatusData.value.operationTime}`;
    let switchRoleName = localStorage.getItem('switchRoleName')
    let formLength = switchRoleName === 'Admin'? 3:4
    const sortFlag = ref(false)
    sortFlag.value = switchRoleName === 'RM' ? true: false
    const data = reactive({
      tabName: "",
      insertCount: "",
      updateCount: "",
      status: "",
      operator: "",
      operationTime: "",
    });
    const pageData = reactive({
      tableData: [],
      activeKey: "code",
      dictData: {
        codeType: [],
        projectGroupType: [],
        projectStatus: [],
        projectType: [],
        YorN: [],
        account: [],
        pl: [],
        subPl: [],
      },
      accountPlRelation: [],
      sortData: {
        field: "",
        order: "",
      },
    });
    //筛选条件缓存
    let filterDataCache = {};
    const arveTip = ref("");
    const filterLayoutRef = ref();
    const formRef = ref();
    let initData = {
      engagementCondition: undefined,
      engagementManagerCondition: undefined,
      account: [],
      pl: [],
      subPl: [],
      projectStatus: [],
      projectType: [],
      codeType: [],
      crossChargeableField: undefined,
      activeStatus: undefined,
    }
    const moreSeachHanlder = () => {
      doSearch()
      closeMoreForm()
    }
    const formData = reactive({...initData});
    //列表项
    const columns = computed(() => {
      if (pageData.activeKey === "code") {
        return [
          {
            title: "Engagement Code",
            dataIndex: "engagementCode",
            key: "engagementCode",
            ellipsis: true,
            sorter: true,
            sortOrder:
              pageData.sortData.field === "engagementCode" &&
              pageData.sortData.order,
            width: 200,
          },
          {
            title: "Engagement Description",
            dataIndex: "engagementDescription",
            key: "engagementDescription",
            ellipsis: true,
            width: 350,
          },
          {
            title: "Code Type",
            dataIndex: "codeType",
            key: "codeType",
            ellipsis: true,
            width: 200,
          },
          {
            title: "Account",
            dataIndex: "account",
            key: "account",
            sorter: true,
            sortOrder:
              pageData.sortData.field === "account" && pageData.sortData.order,
            ellipsis: true,
            width: 200,
          },
          {
            title: "Domain",
            dataIndex: "domain",
            key: "domain",
            ellipsis: true,
            width: 200,
          },
          {
            title: "P&L",
            dataIndex: "pl",
            key: "pl",
            sorter: true,
            sortOrder:
              pageData.sortData.field === "pl" && pageData.sortData.order,
            ellipsis: true,
            width: 200,
          },
          {
            title: "Sub_P&L",
            dataIndex: "subPl",
            key: "subPl",
            // sorter: true,
            // sortOrder: pageData.sortData.field === 'subPl' && pageData.sortData.order,
            ellipsis: true,
            width: 200,
          },
          {
            title: "Project Start Date",
            dataIndex: "projectStartDate",
            key: "projectStartDate",
            ellipsis: true,
            width: 250,
          },
          {
            title: "Project Closed Date",
            dataIndex: "projectCloseDate",
            key: "projectCloseDate",
            customRender: ({ record }) => {
              return record.projectStatus == "Close"
                ? record.projectCloseDate
                : record.projectCompletionDate;
            },
            sorter: true,
            sortOrder:
              pageData.sortData.field === "projectCloseDate" &&
              pageData.sortData.order,
            ellipsis: true,
            width: 250,
          },
          {
            title: "Project Completion Date",
            dataIndex: "projectCompletionDate",
            key: "projectCompletionDate",
            // sorter: true,
            // sortOrder: pageData.sortData.field === 'projectCompletionDate' && pageData.sortData.order,
            ellipsis: true,
            width: 350,
          },
          {
            title: "EM Number",
            dataIndex: "engagementManagerNumber",
            key: "engagementManagerNumber",
            ellipsis: true,
            width: 260,
          },
          {
            title: "EM Name",
            dataIndex: "engagementManagerName",
            key: "engagementManagerName",
            ellipsis: true,
            width: 260,
          },
          {
            title: "Project Controller Number",
            dataIndex: "projectControllerNumber",
            key: "projectControllerNumber",
            ellipsis: true,
            width: 350,
          },
          {
            title: "Lead Industry Name",
            dataIndex: "leadIndustryName",
            key: "leadIndustryName",
            ellipsis: true,
            width: 350,
          },
          {
            title: "Project Group Type",
            dataIndex: "projectGroupType",
            key: "projectGroupType",
            ellipsis: true,
            width: 350,
          },
          {
            title: "Project Status",
            dataIndex: "projectStatus",
            key: "projectStatus",
            sorter: true,
            sortOrder:
              pageData.sortData.field === "projectStatus" &&
              pageData.sortData.order,
            ellipsis: true,
            width: 250,
            slots: { customRender: "status" },
          },
          {
            title: "Project Type",
            dataIndex: "idcProjectType",
            key: "idcProjectType",
            ellipsis: true,
            width: 250,
          },
          {
            title: "Organization Name",
            dataIndex: "organizationName",
            key: "organizationName",
            ellipsis: true,
            width: 250,
          },
          {
            title: "公用Code",
            dataIndex: "commonCode",
            key: "commonCode",
            ellipsis: true,
            width: 200,
            customRender: ({ text }) =>
              text === "0" ? "N" : text === "1" ? "Y" : "",
          },
          // {
          //   title: "Cross Chargeable Field",
          //   dataIndex: "crossChargeableField",
          //   key: "crossChargeableField",
          //   ellipsis: true,
          //   width: 210,
          //   align:"center"
          // },
          // {
          //   title: "Project Type",
          //   dataIndex: "projectType",
          //   key: "projectType",
          //   ellipsis: true,
          //   width: 150,
          // },
          // {
          //   title: "Active",
          //   dataIndex: "activeStatus",
          //   key: "activeStatus",
          //   ellipsis: true,
          //   width: 150,
          //   align:"center"
          // },
          // {
          //   title: "操作",
          //   ellipsis: true,
          //   width: 170,
          //   align:"center",
          //   fixed: 'right',
          //   slots: { customRender: 'operation' },
          // },
        ];
      } else {
        return [
          {
            title: "Engagement Code",
            dataIndex: "engagementCode",
            key: "engagementCode",
            ellipsis: true,
            width: 250,
          },
          {
            title: "Code Type",
            dataIndex: "codeType",
            key: "codeType",
            ellipsis: true,
            width: 200,
          },
          {
            title: "Account",
            dataIndex: "account",
            key: "account",
            sorter: true,
            sortOrder:
              pageData.sortData.field === "account" && pageData.sortData.order,
            ellipsis: true,
            width: 200,
          },
          {
            title: "Domain",
            dataIndex: "domain",
            key: "domain",
            ellipsis: true,
            width: 200,
          },
          {
            title: "P&L",
            dataIndex: "pl",
            key: "pl",
            sorter: true,
            sortOrder:
              pageData.sortData.field === "pl" && pageData.sortData.order,
            ellipsis: true,
            width: 200,
          },
          {
            title: "Sub_P&L",
            dataIndex: "subPl",
            key: "subPl",
            // sorter: true,
            // sortOrder: pageData.sortData.field === 'subPl' && pageData.sortData.order,
            ellipsis: true,
            width: 200,
          },
          {
            title: "Project Start Date",
            dataIndex: "projectStartDate",
            key: "projectStartDate",
            ellipsis: true,
            width: 350,
          },
          {
            title: "Project Closed Date",
            dataIndex: "projectCloseDate",
            key: "projectCloseDate",
            customRender: ({ record }) => {
              return record.projectStatus == "Close"
                ? record.projectCloseDate
                : record.projectCompletionDate;
            },
            ellipsis: true,
            width: 350,
          },
          {
            title: "Leading CSM Number",
            dataIndex: "engagementManagerNumber",
            key: "engagementManagerNumber",
            ellipsis: true,
            width: 350,
          },
          {
            title: "Leading CSM Name",
            dataIndex: "engagementManagerName",
            key: "engagementManagerName",
            ellipsis: true,
            width: 350,
          },
          // {
          //   title: "Code预计开出时间",
          //   dataIndex: "taskCommitDate",// @todo 待确定字段名
          //   key: "taskCommitDate",
          //   ellipsis: true,
          //   width: 170,
          // },
        ];
      }
    });
    const tableRef = ref();
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        getTableData(filterDataCache);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        getTableData(filterDataCache);
      },
    });
    // 获取筛选框字典
    const getSelectOptions = async () => {
      try {
        const dictKeyStr =
          "codeType,projectGroupType,projectStatus,projectType,YorN";
        const res = await $api.getDictionaryList({
          params: { dictType: dictKeyStr },
        });
        Object.assign(pageData.dictData, generateSelectOptions(res.data));
      } catch (e) {
        console.log(e);
      }
    };
    //构建Select Options
    const generateSelectOptions = (resData) => {
      for (const resDataKey in resData) {
        if (resData[resDataKey].length > 0) {
          resData[resDataKey].map((item) => {
            item.value = item.dictCode;
            item.label = item.dictValue;
          });
        }
      }
      return resData;
    };
    //获取Account、P&L、Sub_P&L Options
    let originAccountPlItems = {}; //原始数据
    const getAccountPlItems = async () => {
      try {
        const res = await $api.getAccountPlItemsApi();
        originAccountPlItems = cloneDeep(res.data);
        Object.assign(pageData.dictData, generateAccountPlItems(res.data));
      } catch (e) {
        console.log(e);
      }
    };
    //生成 Account、P&L、Sub_P&L Options
    const generateAccountPlItems = (resData) => {
      const data = {};
      for (const resDataKey in resData) {
        if (resData[resDataKey].length > 0) {
          data[resDataKey] = resData[resDataKey].map((item) => {
            return {
              value: item,
              label: item,
            };
          });
        } else {
          data[resDataKey] = [];
        }
      }
      return data;
    };
    //获取 Account、P&L、Sub_P&L 关系
    const getAccountPlRelations = async () => {
      try {
        const res = await $api.getAccountPlsApi();
        pageData.accountPlRelation = res.data;
        filteredData.value = pageData.accountPlRelation;
      } catch (e) {
        console.log(e);
      }
    };
    //Account、P&L、Sub_P&L change事件
    const handleAccountPlChange = (key) => {
      const matchData = getMatchData(key);
      Object.assign(pageData.dictData, generateAccountPlItems(matchData));
    };

    const filteredData = ref([]);

    //获取已选中的Account、P&L、Sub_P&L相匹配的数据
    const getMatchData = (key) => {
      const keysArr = ["account", "pl", "subPl"];
      // 清空下级选中数据
      emptySubSelectedData(keysArr, key);
      const { account, pl, subPl } = formData;

      // 获取选中值对应的关系数据
      // filteredData.value = pageData.accountPlRelation.filter(item => {
      //   switch (key) {
      //     case 'account':
      //       return (account.length > 0 && account.includes(item.account) || account.length == 0);
      //     case 'pl':
      //       return (pl.length > 0 && pl.includes(item.pl) || pl.length == 0);
      //     case 'subPl':
      //       return (subPl.length > 0 && subPl.includes(item.subPl) || subPl.length == 0);
      //   }
      // })

      // 若筛选项都为空，取全量的关系数据进行展示
      if (account.length == 0 && pl.length == 0 && subPl.length == 0) {
        filteredData.value = pageData.accountPlRelation;
      } else {
        // Sub_P&L不过滤Account和P&L
        if (key === "subPl") return;
        // 查询筛选符合三个筛选项的数据
        const selectedObj = {
          account: {},
          pl: {},
          subPl: {},
        };
        account.length > 0 &&
          account.forEach((item) => {
            selectedObj.account[item] = true;
          });
        pl.length > 0 &&
          pl.forEach((item) => {
            selectedObj.pl[item] = true;
          });
        subPl.length > 0 &&
          subPl.forEach((item) => {
            selectedObj.subPl[item] = true;
          });
        filteredData.value = pageData.accountPlRelation.filter((item) => {
          if (
            (account.length > 0 ? selectedObj.account[item.account] : true) &&
            (pl.length > 0 ? selectedObj.pl[item.pl] : true) &&
            (subPl.length > 0 ? selectedObj.subPl[item.subPl] : true)
          ) {
            return item;
          }
        });
        //删掉选中数据
        keysArr.splice(keysArr.indexOf(key), 1);
      }
      const resData = {};
      keysArr.forEach((itemKey) => {
        const keyMatchData = filteredData.value
          .map((item) => item[itemKey])
          .filter((item) => item !== null);
        resData[itemKey] = [...new Set(keyMatchData)];
        //对Account、P&L、Sub_P&L已选中的值进行过滤 去掉不符合条件的值
        deleteSelectedValue(itemKey, resData[itemKey]);
      });
      return resData;
    };
    //对Account、P&L、Sub_P&L已选中的值进行过滤 去掉不符合条件的值
    const deleteSelectedValue = (key, keyMatchData) => {
      const keySelectedData = formData[key];
      formData[key] = keySelectedData.filter((item) =>
        keyMatchData.includes(item)
      );
    };
    const emptySubSelectedData = (keysArr, key) => {
      const keyIndex = keysArr.indexOf(key);
      for (let i = keyIndex + 1; i < keysArr.length; i++) {
        formData[keysArr[i]] = [];
      }
    };
    const tableHeight = ref(0);
    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };
    //reset Account、P&L、Sub_P&L options
    const resetAccountPlOptions = () => {
      Object.assign(
        pageData.dictData,
        generateAccountPlItems(originAccountPlItems)
      );
    };
    //tab切换
    const tabChange = () => {
      isShowMoreList.value = false
      if (Operation.value) {
        getArveTips();
      }
      window.addEventListener("resize", getSize);
      getSize();
      //清空form表单
      resetForm();
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    };
    const StatusData = ref({});
    const getArveTips = async () => {
      const FuncName =
        pageData.activeKey === "code"
          ? "getcodeStatusData"
          : "getcodeTbdStatusData";
      await $api[FuncName]().then((res) => {
        console.log(res.data);
        StatusData.value = res.data;
      });
      data.tabName =
        pageData.activeKey === "code" ? "Code主数据" : "TBD Code主数据";
      // const tips = `${tabName}
      // 新增数据数:${StatusData.value.insertCount},
      // 更新数据数:${StatusData.value.updateCount},
      // 任务状态:${getStatusInAccount(StatusData.value.status)},
      // 操作人:${StatusData.value.operator},
      // 操作时间:${StatusData.value.operationTime}`;
      // arveTip.value = tips;
      data.insertCount = StatusData.value.insertCount;
      data.updateCount = StatusData.value.updateCount;
      data.status = getStatusInAccount(StatusData.value.status);
      data.operator = StatusData.value.operator;
      data.operationTime = StatusData.value.operationTime;
    };
    //keep-alive 组件 onActivated生命周期
    onActivated(() => {
      tabChange();
    });
    //重置表单
    const resetForm = () => {
      formRef.value.resetFields();
      Object.assign(formData,initData)
      pagination.pageSize = 12;
      pagination.current = 1;
      //重置 Account、P&L、Sub_P&L options
      resetAccountPlOptions();
      doSearch(formData);
    };
    //点击搜索按钮
    const doSearch = () => {
      pagination.current = 1;
      //缓存搜索项
      filterDataCache = cloneDeep(formData);
      getTableData(filterDataCache);
    };
    //筛选展开收起
    const getChartContainerStyle = () => {
      getSize();
    };
    //获取列表数据
    const getTableData = async (filterData) => {
      //删除order为false时的order字段
      if (!pageData.sortData.order) delete pageData.sortData.order;
      //构建请求数据
      const params = {
        ...filterData,
        sort: {
          ...pageData.sortData,
        },
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      };
      try {
        const res =
          pageData.activeKey === "code"
            ? await $api.getCodeListApi(params)
            : await $api.getTBDCodeListApi(params);
        pageData.tableData = res.data.resource;
        pagination.total = res.data.total;
        //判断是否有数据,控制显示左下角控制栏的位置
        if (res.data.total == 0) {
          dataBoxShow.value = false;
          noDataShow.value = true;
        } else {
          dataBoxShow.value = true;
          noDataShow.value = false;
        }
      } catch (e) {
        console.log(e);
      }
    };
    const importModalVisible = ref(false);
    //导入
    const handleImport = () => {
      importModalVisible.value = true;
      // console.log(pageData.activeKey);
    };
    const handleCloseModal = (type) => {
      if (type === "import") {
        importModalVisible.value = false;
      }
    };
    //导入更新
    const doUpdateImport = async () => {
      console.log("doUpdateImport");
      doSearch();
      await getArveTips();
    };
    //导入新增
    const doInsertImport = () => {
      console.log("doInsertImport");
      // $api.getAccountStatusData().then(res => {
      //   accountStatusData.value = res.data;
      //   $api.getStatusAccountData({
      //     importId: accountStatusData.value.id
      //   }).then((res) => {
      //     tbdCodeList.value = res.data
      //     tbdCodeVisible.value = true
      //   });
      // })
      doSearch();
    };
    //导出
    const handleExport = () => {
      const templateFuncName =
        pageData.activeKey === "code" ? "exportCodeData" : "exportTbdCodeData";
      const params = Object.assign(filterDataCache, {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        sort: {
          ...pageData.sortData,
        },
      });
      $api[templateFuncName](params).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    //下载模板
    const handleDownloadTemplate = () => {
      const templateFuncName =
        pageData.activeKey === "code"
          ? "getCodeTemplate"
          : "getTbdCodeTemplate";
      $api[templateFuncName]().then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    //监听表格change事件 改变排序
    const handleTableChange = (pagination, filters, sorter) => {
      console.log(sorter);
      Object.assign(pageData.sortData, {
        field: sorter.field,
        order: sorter.order,
      });
      getTableData(filterDataCache);
    };
    const roles = computed(() => store.state.app.user.roles);
    const Operation = ref(roles.value.indexOf("1") !== -1);
    //初始化
    const init = () => {
      window.addEventListener("resize", getSize);
      getSelectOptions();
      getAccountPlItems();
      getAccountPlRelations();
      getSize();
      doSearch(formData);
      if (Operation.value) {
        getArveTips();
      }
    };

    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      let params = {
        firstMenu: 'basicDataManagement',
        secondMenu: pageData.activeKey === 'code'?'code':'TBDcode',
        isQueryDefault: data.isQueryDefault
      }
      $api.queryFilterSort(params).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > formLength+1?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const getPlaceholder = (data)=> {
      switch (data) {
        case 'engagementCondition':
          return 'Engagement Description/Code'
        case 'engagementManagerCondition':
          return 'EM Name /Number'
        default :
          ''  // 如果没
      }
    }
    const getOptions = (data)=> {
      switch (data) {
        case 'pl':
          return pageData.dictData.pl
        case 'subPl':
          return pageData.dictData.subPl
        case 'account':
          return pageData.dictData.account
        case 'domain':
          return pageData.dictData.domain
        case 'codeType':
          return pageData.dictData.codeType
        case 'projectStatus':
          return pageData.dictData.projectStatus
        default :
          ''  // 如果没
      }
    }
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = (data) => {
      if(data){
        console.log(123)
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }
    onMounted(() => {
      init();
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });
    watch(
      () => route.query.id,
      () => {
        if (route.query.id !== "0") {
          formData.engagementCondition = [route.query.id];
          doSearch();
        }
      }
    );
    return {
      tabChange,
      arveTip,
      filterLayoutRef,
      resetForm,
      doSearch,
      getChartContainerStyle,
      formData,
      formRef,
      handleImport,
      handleExport,
      handleDownloadTemplate,
      ...toRefs(pageData),
      pagination,
      columns,
      tableRef,
      tableHeight,
      handleAccountPlChange,
      handleTableChange,
      filteredData,
      importModalVisible,
      doUpdateImport,
      doInsertImport,
      handleCloseModal,
      Operation,
      data,
      dataBoxShow,
      noDataShow,
      queryFormList,
      queryList,
      checkList,
      getPlaceholder,
      getOptions,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      formLength,
      sortFlag,
      moreSeachHanlder
    };
  },
});
</script>
<style lang="less" scoped>
@import "~@/style/newTitleTab.less";

.pageWrap {
  margin: 0 10px 10px;
  width: calc(100% - 20px);
  height: calc(100% - 10px);
  display: flex;
  flex-direction: column;
}

.tab-container {
  display: flex;
  flex: 1;
  width: 100%;
  background-color: #ffffff;
  flex-direction: column;
  border-radius: 0 5px 5px 0;
  padding: 10px 20px;

  .table-wrap {
    margin-top: 10px;

    .status{
      font-size: 0.729vw;
      padding: 0.1vw 0.86vw;
      border: 0;

      &.toBeOpen {
        color: #1890FF;
        background: #DBEEFF;
      }
      &.Active {
        color: #58B99E;
        background: #DDF8F0 60%;
      }
      &.Close {
        color: #999999;
        background: #CCCCCC 60%;
      }
    }
  }
}

.arve-tip {
  position: absolute;
  bottom: 20px;
  height: 20px;
  line-height: 20px;
  color: #6f6f6f;
  .text {
    color: #b3b3b3;
  }
  .tabText {
    color: #6f6f6f;
  }
}

.arve-tip1 {
  position: absolute;
  margin-top: 20px;
  height: 20px;
  line-height: 20px;
  color: #6f6f6f;
  .text {
    color: #b3b3b3;
  }
  .tabText {
    color: #6f6f6f;
  }
}

.ml12 {
  margin-left: 12px;
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
